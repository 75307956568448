html {
	font-size: 16px;
}

body {
	@apply bg-background font-[Poppins] text-black;
}

input,
select,
button {
	@apply font-[Poppins] outline-none;
}
table {
	@apply min-w-full border bg-white text-left;
}
thead {
	@apply bg-gray-800 px-4;
}
th {
	@apply min-w-fit break-after-avoid text-ellipsis whitespace-nowrap py-3 px-4 text-left text-xs uppercase text-white;
}
tbody {
	@apply text-sm;
}
tr {
	@apply border-b border-b-gray-300;
}
tbody tr {
	@apply hover:bg-gray-100;
}
td {
	@apply whitespace-nowrap py-3 px-4 align-middle text-gray-600;
}
.vertical-border td {
	@apply border-r border-r-gray-100;
}

.container {
	@apply mx-auto w-full !max-w-[1536px] px-4;
}

.app-input {
	@apply block w-full rounded-sm border-0 bg-white py-2 px-3 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600;
}
.btn-primary {
	@apply rounded-sm bg-primary py-2 px-5 text-sm text-white;
}
.input-label {
	@apply mb-0.5 block text-sm font-medium leading-6 text-gray-900;
}
.pagination {
	@apply flex items-stretch bg-white py-1.5 ring-1 ring-inset ring-gray-300;
}
.btn-green {
	@apply block rounded-sm !bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:!bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600;
}
.btn-white {
	@apply block rounded-sm !bg-white px-3 py-2 text-center text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:!bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600;
}
.text-link {
	@apply cursor-pointer text-indigo-700 hover:underline;
}
.table-window {
	height: calc(100vh - 190px);
}
